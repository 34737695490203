.container {
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  padding: 2rem 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer {
  margin-top: 2rem;
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-evenly;
}

.footer button {
  width: 110px;
  height: 2rem;
  border: 0;
  outline: none;
  border-radius: 5px;
  background: var(--title);
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
}

@media (max-width: 1000px) {
  .footer {
    width: 50%;
  }
}

@media (max-width: 900px) {
  .footer {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .footer {
    width: 70%;
  }
}

@media (max-width: 700px) {
  .footer {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .footer {
    width: 100%;
    flex-direction: column;
  }

  .footer button {
    margin: 0.5rem;
  }
}
