.title {
  font-size: 4rem;
  font-weight: 600;
  color: var(--title);
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
  color: var(--text-hight);
}
