.lowestBeer {
  margin-top: 1.25rem;
  transition: opacity 0.2s linear;
  transform:translate(0);
}

.lowestBeer p {
  color: var(--title);
  font-size: 1.5rem;
  font-weight: normal;
}
