.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
}

.grid > button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  border: none;
  font-size: 2rem;
  outline: transparent;
  width: 40px;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
